
.jwplayer .overlay {
	background-color: rgba(33,33,33,.9);
	bottom: 0;
	color: #FFF;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 100%;
	z-index: 1;

	.vertical-align {
		@include center-align;
		position: relative;
	}

	.button {
		cursor: pointer;
		background-color: rgba(33,33,33,.9);
		border-radius: 5px;
		border: 2px solid grey;
		font-size: 12px;
		margin: 0 5px;
		min-height: 40px;
		min-width: 95px;
		text-align: center;
		text-transform: uppercase;

		&:focus,
		&:hover {
			background-color: #FFF;
			border: 2px solid #FFF;
			color: #333;
			@extend %transition;
		}
	}
}

@include breakpoint ('tablet-small', max) {
	.jwplayer .welcomeBackOverlay {
		h3,
		p {
			font-size: 1em;
			line-height: 1.4em;
			padding: 0 10%;
		}

		.button {
			font-size: 11px;
			min-height: 35px;
			min-width: 85px;
		}
	}
}

@include breakpoint ('phone-wide', max) {
	.jwplayer .welcomeBackOverlay {
		h3,
		p {
			font-size: 0.8em;
		}

		.button {
			font-size: 9px;
			min-height: 28px;
			min-width: 70px;
		}
	}
}
