@import "../imports/_mixins";

// Custom icons used in various overlays.
// These should probably eventually be replaced with SVGs to match JW8.
@font-face {
    font-family: 'corus-icons';
    src: url("./fonts/corus-jw-icons.eot");
    src: url("./fonts/corus-jw-icons.woff") format('woff'),
       url("./fonts/corus-jw-icons.ttf") format('truetype');
}

.corus-icon[class*=" icon-"] {
	font-family: 'corus-icons';
}

// JW SVG overrides.
%jw-custom-icon {
	opacity: 0.8;
	&:hover {
		opacity: 1;
	}	
}

$svg-icon-on: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve"><g><path style="fill:white;" d="M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z M116.1,137.6c-0.5,3.1-1.5,5.9-3,8.2s-3.5,4.2-6.2,5.5s-6.1,2-10.4,2c-4.8,0-8.7-0.7-11.6-2.3s-5.1-3.9-6.6-6.8 c-1.5-3-2.5-6.5-2.8-10.4c-0.5-4.1-0.7-8.6-0.7-13.5s0.2-9.4,0.7-13.5s1.5-7.7,3-10.6c1.5-3,3.7-5.2,6.6-6.9 c2.9-1.7,6.7-2.5,11.6-2.5c4.6,0,8.2,0.7,10.9,2.2c2.7,1.5,4.7,3.4,6,5.7c1.3,2.3,2.2,4.8,2.5,7.5s0.5,5.2,0.5,7.5h-13 c0-4.7-0.6-8.1-1.7-10.2s-3.2-3.2-6.1-3.2c-1.6,0-3,0.4-4,1.3c-1.1,0.8-1.9,2.3-2.5,4.2c-0.6,1.9-1.1,4.5-1.3,7.6 c-0.3,3.2-0.4,7-0.4,11.6c0,4.9,0.2,8.8,0.5,11.8s0.8,5.3,1.5,7c0.7,1.6,1.5,2.7,2.6,3.3c1,0.6,2.2,0.9,3.6,0.9 c1.1,0,2.2-0.2,3.2-0.6c1-0.3,1.9-1.1,2.6-2.3s1.3-2.9,1.7-5c0.4-2.1,0.6-4.9,0.6-8.4h13C116.9,131.2,116.6,134.5,116.1,137.6z M164.5,137.6c-0.5,3.1-1.5,5.9-3,8.2s-3.5,4.2-6.2,5.5s-6.1,2-10.4,2c-4.8,0-8.7-0.7-11.6-2.3c-2.9-1.6-5.1-3.9-6.6-6.8 c-1.6-3-2.6-6.5-2.8-10.4c-0.5-4.1-0.7-8.6-0.7-13.5s0.2-9.4,0.7-13.5s1.5-7.7,3-10.6c1.5-3,3.7-5.2,6.6-6.9 c2.9-1.7,6.7-2.5,11.6-2.5c4.6,0,8.2,0.7,10.9,2.2s4.7,3.4,6,5.7s2.2,4.8,2.5,7.5s0.5,5.2,0.5,7.5h-13c0-4.7-0.6-8.1-1.7-10.2 c-1.1-2.1-3.2-3.2-6.1-3.2c-1.6,0-3,0.4-4,1.3c-1.1,0.8-1.9,2.3-2.5,4.2c-0.6,1.9-1.1,4.5-1.3,7.6c-0.3,3.2-0.4,7-0.4,11.6 c0,4.9,0.2,8.8,0.5,11.8s0.8,5.3,1.5,7c0.7,1.6,1.5,2.7,2.6,3.3c1,0.6,2.2,0.9,3.6,0.9c1.1,0,2.2-0.2,3.2-0.6 c1-0.3,1.9-1.1,2.6-2.3c0.7-1.2,1.3-2.9,1.7-5s0.6-4.9,0.6-8.4h13C165.3,131.2,165,134.5,164.5,137.6z"/></g></svg>' !default;
$svg-icon-off: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve"><path style="fill:white;" d="M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z M200,180 H40V60h160V180z"/><path style="fill:white;" d="M78.3,144.2c1.5,2.9,3.7,5.2,6.6,6.8s6.8,2.3,11.6,2.3c4.3,0,7.7-0.7,10.4-2c2.7-1.3,4.7-3.2,6.2-5.5 s2.5-5.1,3-8.2c0.5-3.1,0.8-6.4,0.8-9.9h-13c0,3.5-0.2,6.3-0.6,8.4s-1,3.8-1.7,5s-1.6,2-2.6,2.3c-1,0.4-2.1,0.6-3.2,0.6 c-1.4,0-2.6-0.3-3.6-0.9c-1.1-0.6-1.9-1.7-2.6-3.3c-0.7-1.7-1.2-4-1.5-7s-0.5-6.9-0.5-11.8c0-4.6,0.1-8.4,0.4-11.6 c0.2-3.1,0.7-5.7,1.3-7.6c0.6-1.9,1.4-3.4,2.5-4.2c1-0.9,2.4-1.3,4-1.3c2.9,0,5,1.1,6.1,3.2s1.7,5.5,1.7,10.2h13 c0-2.3-0.2-4.8-0.5-7.5s-1.2-5.2-2.5-7.5s-3.3-4.2-6-5.7s-6.3-2.2-10.9-2.2c-4.9,0-8.7,0.8-11.6,2.5s-5.1,3.9-6.6,6.9 c-1.5,2.9-2.5,6.5-3,10.6s-0.7,8.6-0.7,13.5s0.2,9.4,0.7,13.5C75.8,137.7,76.8,141.2,78.3,144.2z"/> <path style="fill:white;" d="M126.7,144.2c1.5,2.9,3.7,5.2,6.6,6.8s6.8,2.3,11.6,2.3c4.3,0,7.7-0.7,10.4-2s4.7-3.2,6.2-5.5s2.5-5.1,3-8.2 c0.5-3.1,0.8-6.4,0.8-9.9h-13c0,3.5-0.2,6.3-0.6,8.4c-0.4,2.1-1,3.8-1.7,5s-1.6,2-2.6,2.3c-1,0.4-2.1,0.6-3.2,0.6 c-1.4,0-2.6-0.3-3.6-0.9c-1.1-0.6-1.9-1.7-2.6-3.3c-0.7-1.7-1.2-4-1.5-7s-0.5-6.9-0.5-11.8c0-4.6,0.1-8.4,0.4-11.6 c0.2-3.1,0.7-5.7,1.3-7.6s1.4-3.4,2.5-4.2c1-0.9,2.4-1.3,4-1.3c2.9,0,5,1.1,6.1,3.2s1.7,5.5,1.7,10.2h13c0-2.3-0.2-4.8-0.5-7.5 s-1.2-5.2-2.5-7.5s-3.3-4.2-6-5.7s-6.3-2.2-10.9-2.2c-4.9,0-8.7,0.8-11.6,2.5c-2.9,1.7-5.1,3.9-6.6,6.9c-1.5,2.9-2.5,6.5-3,10.6 s-0.7,8.6-0.7,13.5s0.2,9.4,0.7,13.5C124.1,137.7,125.1,141.2,126.7,144.2z"/></svg>' !default;
$dv-icon-on: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve"><g><path style="fill:white;" d="M101.2,102.9c-0.8-1.8-1.9-3.2-3.3-4.1c-1.5-0.9-3.4-1.4-5.9-1.4h-5.5v45.2h5.8c2.3,0,4.1-0.4,5.5-1.2 s2.5-2.1,3.3-3.9c0.8-1.8,1.3-4.2,1.6-7.1c0.3-2.9,0.4-6.6,0.4-11c0-3.7-0.1-6.9-0.4-9.6C102.4,107.1,101.9,104.8,101.2,102.9z"/> <path style="fill:white;" d="M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z M115,133.8c-0.7,4.2-1.9,7.6-3.6,10.3s-3.9,4.7-6.8,6c-2.8,1.3-6.4,2-10.7,2H73.5V87.9h21.2c4.5,0,8.1,0.7,10.9,2.2 s4.9,3.6,6.4,6.3c1.5,2.7,2.6,6,3.1,9.9s0.8,8.1,0.8,12.9C116,124.7,115.6,129.6,115,133.8z M151.7,152.1h-16.6l-14.8-64.3h13.9 l9.1,47.5h0.3l9.5-47.5h13.4L151.7,152.1z"/></g></svg>' !default;
$dv-icon-off: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 240 240" style="enable-background:new 0 0 240 240;" xml:space="preserve"><g><path style="fill:white;" d="M215,40H25c-2.7,0-5,2.2-5,5v150c0,2.7,2.2,5,5,5h190c2.7,0,5-2.2,5-5V45C220,42.2,217.8,40,215,40z M200,180 H40V60h160V180z"/><path style="fill:white;" d="M104.7,150.2c2.8-1.3,5.1-3.3,6.8-6s2.9-6.1,3.6-10.3s1-9.1,1-14.7c0-4.7-0.3-9-0.8-12.9s-1.6-7.1-3.1-9.9 c-1.5-2.7-3.7-4.8-6.4-6.3c-2.8-1.5-6.4-2.2-10.9-2.2H73.5v64.3h20.4C98.3,152.1,101.8,151.5,104.7,150.2z M86.5,142.6V97.4H92 c2.5,0,4.4,0.5,5.9,1.4s2.6,2.3,3.3,4.1c0.8,1.8,1.2,4.1,1.5,6.9c0.2,2.8,0.4,6,0.4,9.6c0,4.4-0.1,8-0.4,11 c-0.3,2.9-0.8,5.3-1.6,7.1c-0.8,1.8-1.9,3.1-3.3,3.9c-1.4,0.8-3.2,1.2-5.5,1.2L86.5,142.6L86.5,142.6z"/><polygon style="fill:white;" points="151.7,152.1 166.5,87.9 153.1,87.9 143.5,135.4 143.3,135.4 134.2,87.9 120.3,87.9 135.1,152.1 "/></g></svg>' !default;

.jw-skin-corus {
	.jw-icon-cc,
	.jw-icon-dv {
		@extend %jw-custom-icon;
	}

	.jw-svg-icon-cc-on {
		@include jw-custom-svg-icon(svg-uri($svg-icon-on));
	}

	.jw-svg-icon-cc-off {	
		@include jw-custom-svg-icon(svg-uri($svg-icon-off));
	}
	
	.jw-svg-icon-dv-on {
		@include jw-custom-svg-icon(svg-uri($dv-icon-on));
	}

	.jw-svg-icon-dv-off {
		@include jw-custom-svg-icon(svg-uri($dv-icon-off));
	}
}
