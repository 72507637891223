﻿@import '../../styles/imports/_breakpoints';
@import '../../styles/imports/_vars';

.videoplayerAuthMessage {
    color: white;
    position: relative;

    .videoplayerAuthMessage-copy {
        bottom: 0px;
        box-sizing: border-box;
        display: inline-block;
        padding: 14px;
        position: absolute;
        width: 100%;
    }

    .videoplayerAuthMessage-figure {
        margin: 0;

        .videoplayerAuthMessage-figure-image {
            display: block;
            width: 100%;
        }
    }

    .videoplayerAuthMessage-cta {
        background: #333;
        display: inline-block;
        padding: 10px;
    }
}
