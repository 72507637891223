﻿@import '../../styles/imports/_breakpoints';
@import '../../styles/imports/_vars';

@keyframes animateMyFace {
  0% {
    content: "\e619";
  }
  100% {
    content: "\e618";
  }
}

.adBlockerMessage {
    width: 70%;
    @include breakpoint('tablet-medium', 'max') {
        width: 100%;
    }
}

.videoErrorMessage.adBlockerMessage {
    .videoErrorMessage-copy {
        display: flex;
        flex-direction: column;
        font-size: 1.4em;
        justify-content: center;
        padding: 0 15%;
        text-align: center;
        a {
            color: #fff;
        }

        .icon-face{
            &:after {
            animation: animateMyFace 1s linear infinite alternate;
            content: "\e619";
            display: block;
            font-size: 60px;
            padding-bottom: 30px;
            }
            @include breakpoint("phablet", "max") {
            display: none;
            }
        }

        .icon-ctrl {
            display: inline-block;
            transform: rotate(90deg);

            &:before {
                content: "\e617";
                font-size: 16px;
                vertical-align: middle;
            }
        }

        .videoErrorMessage-copy-message {
            padding: 10px 0;
            font-size: 18px;
            a {
                text-decoration: underline;
                white-space: nowrap;
            }
            @include breakpoint("tablet-small", "max") {
                font-size: 16px;
            }

            @include breakpoint("phablet", "max") {
                font-size: 14px;
            }
        }

        .videoErrorMessage-copy-title {
            text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
            @include breakpoint("phablet", "max") {
            font-size: 17px;
            }

        }

        .videoErrorMessage-cta-wrapper {
            padding: 10px 0;
            @include breakpoint("phablet", "max") {
                padding: 0;
            }
            .videoErrorMessage-cta {
                font-size: 16px;
                font-weight: bold;
                padding: 10px 15px;
                text-transform: uppercase;
                vertical-align: middle;
                @include breakpoint("phablet", "max") {
                    display: none;
                    padding: 0;
                }
            }
        }
    }
}
