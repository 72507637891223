$nextup-container-height: 75px;

.jw-skin-corus {
    .jw-nextup-container {
        left: auto;
        max-width: 350px;
        padding-right: 0;
        right: 10px;
        width: 46.5%;

        .jw-nextup {
            width: 100%;
        }

        .jw-nextup-close {            
            font-size: 0.7em;
            height: 2.3em;
            margin: 0.625em;
            padding: 0;
            width: 2.3em;
            z-index: 2;
            &:focus,
            &:hover {
                cursor: pointer;
            }
        }

        .jw-nextUp-link {
            height: 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }

        .jw-nextup-header {
            display: none;
        }

        .jw-nextup-title {
            height: $nextup-container-height;
            padding: 10px 37px 10px 10px;
        }

        .jw-nextup-thumbnail {
            height: $nextup-container-height;
            position: relative;
            &:after {
                bottom: 0;
                color: #FFF;
                content: "WATCH NOW";
                display: block;
                font-size: 0.6em;
                left: 0;
                letter-spacing: 0.09em;
                padding: 0.19em 0;
                position: absolute;
                right: 0;
                text-align: center;
            }
        }

        .jw-nextup-tooltip {
            height: $nextup-container-height;
        }
    }

    // 0-639px 
    &.jw-breakpoint-0,
    &.jw-breakpoint-1,
    &.jw-breakpoint-2,
    &.jw-breakpoint-3 {
        .jw-nextup-container {
            width: 50.5%;
            .jw-nextup-close {
                font-size: 0.55em;
                height: 2.0em;
                width: 2.0em;
            }
            .jw-nextup-title {
                padding-right: 30px;
            }
        }
    }
}
