.videoErrorMessage {
	&.message--videoNotFound {
		&:before {
			background: $background-color;
			content: '';
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 1;
		}

		.videoErrorMessage-copy {
			@include center-align;
			background: 0;
			bottom: auto;
			z-index: 2;
		}

		.videoErrorMessage-copy-title,
		.videoErrorMessage-copy-message {
			text-align: center;
		}

		.videoErrorMessage-copy-title {
			padding-bottom: 10px;
		}
	}
}
