/* Corus default adblock styles */
.videoplayerAuthMessage {
    font-family: 'proxima-nova', Verdana, sans-serif;
    min-height: 147px;
    
    .videoplayerAuthMessage-copy {
        padding-bottom: 30px;
        bottom: 0;
        background-color: rgba($theme-color, 0.5);
        text-align: center;
        padding-bottom: 30px;

        .videoplayerAuthMessage-copy-title {
            font-size: 30px;
            margin: 0;
            padding: 10px 0 6px;
        }

        .videoplayerAuthMessage-copy-message {
            font-size: 22px;
            padding: 0 0 12px;
        }

        .videoplayerAuthMessage-cta {
            background: $theme-color;
            font-size: 14px;
            font-weight: normal;
            padding: 10px 15px 10px 0;

            &:before {
                background-color: $black;
                content: "\e90c";
                font-family: 'corus-icons';
                font-size: 16px;
                padding: 10px 15px 13px;
            }

            .videoplayerAuthMessage-cta-label {
                padding-left: 10px;
            }
        }
    }
}

.jwplayer-auth-messaging {
    cursor: pointer;
    top: 0;
    width: 100%;
    z-index: 999;

    .jwplayer-container.playlist-right & {
        width: 70%;
    }
}

@include breakpoint ('tablet-medium', 'max') {
    .jwplayer-container {
        &.playlist-right {
            .jwplayer-auth-messaging {
                width: 100%;
            }
        }
    }
}

@include breakpoint ('phablet', 'max') {
    .videoplayerAuthMessage {
        .videoplayerAuthMessage-copy {
            height: 100%;
            bottom: 0;
            padding-top: 10%;
        }
    }
}

@include breakpoint ('phone-wide', 'max') {
    .videoplayerAuthMessage {
        .videoplayerAuthMessage-copy {
            .videoplayerAuthMessage-copy-title {
                font-size: 16px;
            }

            .videoplayerAuthMessage-copy-message {
                font-size: 14px;
            }
        }
    }
}
