@import "corus.vars";
@import "corus.authmessage";
@import "corus.contentblocking";
@import "corus.playlist.component";
@import "corus.nextup.component";
@import "corus.icons";

.jw-skin-corus {
	button {
		&:hover{
		cursor: pointer;
		}
	}

	.jw-text, .jw-reset {
		font-family: 'proxima-nova', Verdana, sans-serif;
	}

	.jw-text {
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.jw-title {
		background: linear-gradient(rgba(black, 0.5), transparent);
		font-size: 1.125em;
		height: 6.375em;
		padding-top: 1.5em;
	}

	.jw-title-primary, .jw-title-secondary {
		line-height: normal;
		margin-top: 0;
		max-width: 100%;
		min-height: 0;
		overflow: hidden;
		padding: 0 25px;
	}

	.jw-title-primary {
		font-size: 1.2em;
	}

	.jw-title-secondary {
		font-size: 0.85em;
		padding-top: 2px;
	}

	.jw-plugin-related-open {
		z-index: 2;
	}

	.jw-slider-time {
		padding-top: 10px;
	}

	&.jw-error .jw-display-icon-container {
		display: inline-block;

		.jw-icon::before {
			line-height: 1.65;
		}
	}

	&.jw-breakpoint-0,
	&.jw-breakpoint-1 {
		.jw-title {
			padding-top: 15px;
		}

		.jw-title-primary {
			font-size: 0.85em;
		}

		.jw-title-primary,
		.jw-title-secondary {
			padding: 0 15px;
		}
	}

	.jw-progress {
		background-color: $theme-color;
	}

	// Buttons / settings menu to disable.
	.jw-settings-open .jw-settings-menu,
	.jw-icon-settings,
	.jw-related-btn,
	.jw-icon-next,
	.jw-icon-hd,
	.jw-display-icon-rewind,
	.jw-display-icon-next {
		display: none;
	}

	@include breakpoint('tablet-medium', 'max') {
		.jw-related-btn {
			display: flex;
		}
	}

	// DV button
	.jw-icon-dv.hidden {
		display: none;
	}

	.jw-svg-icon-dv-on {
		display: block;
	}

	.jw-svg-icon-dv-off {
		display: none;
	}

	.jw-off {
		.jw-svg-icon-dv-on {
			display: none;
		}

		.jw-svg-icon-dv-off {
			display: block;
		}
	}
}
