/* Corus default playlist component styles */
.jw-skin-corus ~ .playlist, 
.playlist.jw-skin-corus {
    font-family: 'proxima-nova', Verdana, sans-serif;

    .playlist-content {
        .playlist-item {
            .playlist-item-number {
                display: none;
            }
        }
    }

    .playlist-header {
        .playlist-length {
            display: none;
        }
    }    
}
