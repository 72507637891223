@import "imports/vars";
@import "imports/breakpoints";
@import "imports/mixins";
@import "skins/corus";
@import "components";

.clear {
	clear: both;
}

.jwplayer {
	font-family: Arial, sans-serif;
	vertical-align: top;

	video {
		max-width: initial;
	}

	img {
		max-width: none;
		max-width: unset;
	}

	.jw-controlbar {
		z-index: 10000010;
	}

	&.jw-state-paused:not(.jw-flag-ads) {
		.jw-title {
			display: block;
		}
	}
}

.jwplayer-container {
	position: relative;

	.buffering {
		background-color: #333;
	}
	.message {
		margin: 0 10px 15px;
	}
}
