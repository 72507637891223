@import "vars";

// Mixin for icon default formatting
.jw-icon {
	background-color: transparent;
}

/// Replace `$search` with `$replace` in `$string`
/// @link https://css-tricks.com/snippets/sass/str-replace-function/
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/// URL encoding for SVGs - just a few replacements to get it working in IE11.
/// @link https://github.com/waldemarfm/sass-svg-uri
/// @param {String} $svg - SVG image to encode
/// @return {String} - Encoded SVG data uri
@function svg-uri($svg) {
	$encoded: '';
	$slice: 2000;
	$index: 0;
	$loops: ceil(calc(str-length($svg) / $slice));

	@for $i from 1 through $loops {
		$chunk: str-slice($svg, $index, $index + $slice - 1);
		$chunk: str-replace($chunk, '"', "'");
		$chunk: str-replace($chunk, '%', '%25');
		$chunk: str-replace($chunk, '<', '%3C');
		$chunk: str-replace($chunk, '>', '%3E');
		$chunk: str-replace($chunk, '&', '%26');
		$chunk: str-replace($chunk, '#', '%23');
		$encoded: #{$encoded}#{$chunk};
		$index: $index + $slice;
	}

	@return "data:image/svg+xml;charset=utf8,#{$encoded}";
}


@mixin jw-custom-svg-icon($url) {
	path {
		display: none;
	}

	background-image: url($url);
	background-repeat: no-repeat;
	background-size: contain;
}

@mixin center-align($x: 50%, $y: 50%) {
	-moz-transform: translateY(-$y) translateX(-$x);
	-o-transform: translateY(-$y) translateX(-$x);
	-ms-transform: translateY(-$y) translateX(-$x);
	-webkit-transform: translateY(-$y) translateX(-$x);
	transform: translateY(-$y) translateX(-$x);
	top: $y;
	left: $x;
}


%transition {
	-webkit-transition: all 500ms ease-in-out;
	-moz-transition: all 500ms ease-in-out;
	-o-transition: all 500ms ease-in-out;
	transition: all 500ms ease-in-out;
}
