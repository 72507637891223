﻿@import '../../styles/imports/_breakpoints';
@import '../../styles/imports/_vars';

.videoErrorMessage {
    color: white;
    font-size: 18px;
    position: relative;

    .videoErrorMessage-copy {
        background: $background-color;
        bottom: 0px;
        box-sizing: border-box;
        padding: 14px;
        position: absolute;
        top: 0px;
        width: 100%;

        @include breakpoint("tablet") {
            padding: 27px;
        }

        .videoErrorMessage-copy-message {
            font-size: 0.75em;

            @include breakpoint("tablet") {
                font-size: 0.85em;
            }

            p {
                margin: 0 0 5px;

                @include breakpoint("tablet") {
                    margin: 0 0 10px;
                }
            }
        }

        .videoErrorMessage-copy-title {
            font-size: 0.85em;
            margin: 0;
            padding: 0px;

            @include breakpoint("tablet") {
                font-size: 1.2em;
                padding-bottom: 2px;
            }
        }
    }

    .videoErrorMessage-copy--noFigure {
        background-color: rgba(33,33,33, 1);
    }

    .videoErrorMessage-figure {
        height: 0;
        margin: 0;
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;

        .videoErrorMessage-figure-image {
            display: block;
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
        }
    }
}
