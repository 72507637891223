.jwplayer .countdown-overlay {
	background-size: cover;
	z-index: 10000011 !important;

	&:before {
		background-color: rgba(33,33,33,.9);
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		width: 100%;
	}

	.countdown-cancel {
		background-color: transparent;
		border: none;
		color: #FFF;
		font-size: 0.8em;
		padding: 10px;
		text-transform: uppercase;
	}

	.countdown-duration,
	.countdown-episode,
	.countdown-date {
		font-size: 16px;
		&:not(:first-child) {
			&:before {
				content: "|";
				display: inline-block;
				margin: 0 10px;
			}
		}
	}

	.countdown-info {
		color: #CCC;
		font-size: 0;
		padding-bottom: 10px;
	}

	.countdown-title {
		font-size: 1.7em;
		font-weight: bolder;
		line-height: normal;
		margin: 0;
		padding-bottom: 15px;
		text-transform: uppercase;
	}

	.countdown-show {
		font-size: 1.4em;
		font-weight: bolder;
		line-height: normal;
		margin: 0;
		padding-bottom: 10px;

		span {
			display: block;
		}

		.countdown-episode-name {
			font-size: 0.8em;
			font-weight: lighter;
		}
	}

	.jw-nextup-close {
		background-color: transparent;
		border-radius: 50%;
		display: block;
		font-family: 'corus-icons';
		font-size: 1em;
		height: 2.2em;
		opacity: 1;
		overflow: hidden;
		padding: 0;
		visibility: visible;
		width: 2.2em;
		z-index: 1;
		-webkit-appearance: none;

		&:before {
		    content: "\E615";
			font-size: 0.93em;
		}
	}

	.countdown-svg-container {
		display: block;
		height: 108px;
		margin: 12px auto;
		width: auto;

		svg.countdown-play {
			&:hover {
				cursor: pointer;
			}
			.countdown-circle {
				fill: transparent;
				fill-opacity: 0.1;
				stroke: #CCC;
				stroke-opacity: 0.4;
				stroke-width: 0.3em;
			}
			.countdown-path {
				fill: transparent;
				stroke: #FFF;
				stroke-width: 0.3em;
			}
			.countdown-triangle {
				fill: #FFF;
			}
		}
	}
}

.jw-breakpoint-3 {
	.countdown-overlay {
		.countdown-info {
			font-size: 0.95em;
		}
		.countdown-show {
			font-size: 1.1em;
			padding-bottom: 5px;
		}
		.countdown-svg-container {
			height: 85px;
		}
		.countdown-title {
			font-size: 1.3em;
			padding-bottom: 10px;
		}
	}
}

.jw-breakpoint-2,
.jw-breakpoint-1,
.jw-breakpoint-0 {
	.countdown-overlay {
		.countdown-info {
			font-size: 0.85em;
			padding-bottom: 5px;
		}
		.countdown-show {
			font-size: 0.9em;
			padding-bottom: 3px;
		}
		.countdown-svg-container {
			height: 64px;
			margin: 5px auto;
		}
		.countdown-title {
			font-size: 1em;
			padding-bottom: 4px;
		}
	}
}
