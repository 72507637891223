﻿/* Corus default adblock styles */
.jw-skin-corus.videoErrorMessage {
    font-family: 'proxima-nova', Verdana, sans-serif;
}

.jwplayer-messaging {
	top: 0;
	z-index: 999;
}

.jwplayer-container {
	.jwplayer-messaging {
		width: 100%;
	}

	&.playlist-right {
		.jwplayer-messaging {
			width: 70%;
		}
	}
}

@include breakpoint ('tablet-medium', 'max') {
    .jwplayer-container {
        &.playlist-right {
            .jwplayer-messaging {
                width: 100%;
            }
        }
    }
}