@import '../../styles/imports/vars';
@import '../../styles/imports/breakpoints';
@import '../../styles/imports/mixins';

$bg-color: rgba(00,00,00,.8) !default;


// Class gets added to current player
.playlist-right{
    .jwplayer{
        float: left;
        vertical-align: top;
        width: 70% !important;
    }
    .jw-playlist-dock-btn{
        display: none;
    }
}

// Playlist styles
.playlist{
    background-color: rgba(00,00,00,.4);
    bottom: 0;
    color: #FFF;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right:0;
    top: 0;
    width: 30%;
    -webkit-overflow-scrolling: touch;

    &:after{
        display: block;
        content: '';
        clear: both;
    }

    a,a:link{
        color: #FFF;
        display: inline-block;
        text-decoration: none;
    }

    .playlist-content{
        margin: 0;
        overflow-y: auto;
        padding: 0;

        &.playlist-content--related {
            .playlist-item{
                padding: 0;

                .playlist-link{
                    padding: 10px;
                }

                &:first-child{
                    display: none;
                }

                &:nth-child(2){
                    padding-bottom: 0;

                    &:after{
                        content: "";
                        display: block;
                        font-size: 18px;
                        font-weight: 900;
                        margin: 10px 0;
                        border-bottom: solid 1px #FFF;
                    }

                    &:hover{
                        background-color: inherit;

                        .playlist-item-show{
                            color: #FFF;
                        }

                        .playlist-link{
                            &:hover{
                                background-color: $bg-color;
                                @extend %transition;
                            }
                        }
                    }
                }
            }

            &.playlist-content--nocurrent {
                .playlist-item {
                    &:first-child {
                        display: list-item;
                    }
                }
            }
        }

        .playlist-item{
            font-size: 12px;
            list-style: none;
            padding: 10px;

            &.active{
                background-color: $bg-color;
            }

            &-figure {
                float: left;
                margin: 0;
                position: relative;
                vertical-align: top;
                width: 35%;

                &-image {
                    display: block;
                    margin: 0;
                    padding: 0;
                    width: 100%;
                }

                &-progress {
                    background: red;
                    bottom: 0px;
                    height: 3px;
                    position: absolute;
                }

                &-watched {
                    background: $background-color;
                    bottom: 0px;
                    padding: 1px 3px;
                    position: absolute;
                    text-transform: uppercase;
                }
            }

            &:focus,
            &:hover{
                background-color: $bg-color;
                @extend %transition;
            }

            .playlist-item-duration,
            .playlist-item-episode,
            .playlist-item-season{
                &:not(:first-child){
                    &:before{
                        content: "|";
                        display: inline-block;
                        margin: 0 4px;
                    }
                }
                font-size: 11px;
            }

            .playlist-item-episode-name{
                display: block;
                padding-bottom: 1px;
                padding-left: 10px;
            }

            .playlist-item-info{
                display: block;
                float: right;
                width: 65%;
            }

            .playlist-item-metadata{
                font-size: 0;
                span:first-child{
                    padding-left: 10px;
                }
            }

            .playlist-item-show{
                display: block;
                font-size: 16px;
                font-weight: bolder;
                padding-bottom: 2px;
                padding-left: 10px;
            }

            .playlist-link{
                display: block;
                z-index: 1;
                &:after{
                    clear: both;
                    content: '';
                    display: block;
                }
            }
        }
    }

    .playlist-header{
        padding: 15px 10px 12px;

        .playlist-length{
            font-size: 12px;
        }

        .playlist-title{
            font-size: 18px;
            font-weight: 900;
            margin: 0;
            text-transform: uppercase;
        }
    }
}

@include breakpoint('tablet-medium', 'max') {
    .playlist{
        display: none;
    }

    .playlist-right{
        .jwplayer{
            float: none;
            width: 100% !important;
        }

        .jw-playlist-dock-btn{
            display: block;
        }
    }
}
